import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MDXPage from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import FilterPhrase from '../components/FilterPhrase';
import StateDropdown from '../components/StateDropdown';
import Table from '../components/Table';
import Header from '../components/Header.mdx';
import StaticNavbar from '../components/StaticNavbar.mdx';
export const remainMountedChildren = <>
    <Header mdxType="Header" />
    <StaticNavbar mdxType="StaticNavbar" />
    <FloatingNavbar anchorLinks={[{
    href: '#map',
    label: 'Map'
  }, {
    href: '#list-of-all-tribes',
    label: 'List of All Tribes'
  }]} mdxType="FloatingNavbar" />
    <h2 className="text-center mt-3 mb-3">All Tribal Nations</h2>
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4>
          {'Indigenous Land in '}
          <StateDropdown mdxType="StateDropdown" />
          {' Granted to Universities'}
        </h4>} mapSubtitle="Morrill Act parcels and treaty areas" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = MDXPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageMeta title="All Tribal Nations" mdxType="PageMeta" />
    <a name="list-of-all-tribes" />
    <Section paragraphWidth className="mt-5" mdxType="Section">
  <FilterPhrase name="tribes-ceded-land-in-state" mdxType="FilterPhrase" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Table name="tribes-for-selected-states" pageSize={10} mdxType="Table" />
    </Section>
    <Section figureWidth mdxType="Section">
  <p>
    <em>
      <b>NOTE</b>: Our data shows land cessions that are associated with
      multiple tribal nations. These totals add up to more than 100% of total
      acreage, amount paid, and amount raised. The names above appear as they
      did at time of cession. Many are not in use today, and some are considered
      offensive. For more information, <a href="#about">see our methodology</a>.
    </em>
  </p>
  <p>&nbsp;</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      